import { withFirebase } from 'providers/firebase'

import { useCallback, useEffect, useMemo, useState } from 'react';



import {
  Card,
  Button,
  Stack,
  Collapsible,
  Icon
} from '@shopify/polaris';

import {
  LockMajor
} from '@shopify/polaris-icons';

import { textsTransfer, textsMethod } from 'refs/resource-names'

import { addTransferSearchFields, generateId, tagsToMap } from 'utils/helpers';

import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { updateTransferTags } from 'redux/slices/app-data';

import useJob from 'hooks/useJobs';
import { setCreatingTransfer } from 'redux/slices/transfer-state';
import { addToLogs } from 'redux/slices/app-data';

import FeatureToggle from 'components/FeatureToggle/FeatureToggle';
import TransferMethod from './TransferMethod';
import styled from 'styled-components';
import useFeatures from 'hooks/useFeatures';
import { dbPaths } from 'refs/db-paths';
import useAxios from "hooks/useAxios";

function Actions(props) {
  const {
    db,
    shop,
    onCreate,
    disabled,
    // transferType,
    // onChange
  } = props
  const [loading, loadingSet] = useState(false)
  const [savingDraft, savingDraftSet] = useState(false)
  const [advancedActive, advancedActiveSet] = useState(false)

  const {
    appData: {
      ui: { tags_transfer = [] } = {},
      counts: { transfers = 0 }
    },
    transfer
  } = useAppSelector(s => s)
 

  const defaultMethod = 'instant'

  const { axios } = useAxios()

  const { hasFeature } = useFeatures()

  const { createJob, jobCodes } = useJob()

  const dispatch = useAppDispatch()

  const showAdvanced = useMemo(() => transfer.type === 'internal', [transfer.type])

  const disableAdvanced = useMemo(() => transfer.parent, [transfer.parent])

  const toggleAdvanced = useCallback(() => advancedActiveSet(x => !x), [])

  const addTags = useCallback((tagsToAdd = []) => {
    const newTags = [...new Set([...tags_transfer, ...tagsToAdd])]

    db.doc(dbPaths.appData(shop, 'ui')).set(
      { tags_transfer: newTags },
      { merge: true }
    )

    dispatch(updateTransferTags(newTags))
  }, [db, dispatch, shop, tags_transfer])


  const saveTransfer = useCallback(async (draft) => {
    try {
      dispatch(addToLogs("Saving transfer"))
      dispatch(setCreatingTransfer(true))
      const now = new Date().toISOString()
      const parentId = transfer.id || generateId()
      // const childId = transfer.id ? generateId() : parentId - 1
      const childId = parentId - 1
      // dispatch(addToLogs(`Parent ID: ${parentId}`))
      dispatch(addToLogs(`Child ID: ${childId}`))
      // const { transfers = 0 } = await db.doc(`shops/${shop}/app_data/counts`).get().then(s => s.data() || {})
      const parentName = transfer.name || `T${String(transfers + 1).padStart(4, '0')}`
      const childNameOffset = transfer.name ? 1 : 2
      const childName = `T${String(transfers + childNameOffset).padStart(4, '0')}`
      let isLinked = false
      addTags(transfer.tags)
  
      dispatch(addToLogs("Preparing parent transfer"))
      const parentTransfer = {
        ...transfer,
        id: parentId,
        name: parentName,
        status: draft ? 'draft' : 'processing',
        time_created: transfer.time_created || now,
        tags_map: tagsToMap(transfer.tags),
      }
  
      const childTransfer = {
        ...parentTransfer,
        status: 'draft',
        id: childId,
        name: childName
      }
  
      if (!draft) parentTransfer.time_execution_called = now
  
      if (!draft && !transfer.parent) {
        dispatch(addToLogs("Preparing child transfer"))
        const invertedItems = Object
          .keys(transfer.items)
          .reduce((items, id) => ({ ...items, [id]: { ...transfer.items[id], operation: 'subtract' } }), {})
  
        if (transfer.method === 'split_adjustment') {
          isLinked = true
          childTransfer.type = 'adjustment'
          childTransfer.destination = parentTransfer.destination
          parentTransfer.type = 'adjustment'
          parentTransfer.destination = parentTransfer.origin
          parentTransfer.items = invertedItems
          delete childTransfer.origin
          delete parentTransfer.origin
        } else if (transfer.method === 'split_location') {
          isLinked = true
          childTransfer.origin = transfer.split_location
          parentTransfer.destination = transfer.split_location
        }
  
        if (isLinked) {
          childTransfer.parent = { id: parentId, name: parentName }
          parentTransfer.child = { id: childId, name: childName }
        }
      }
      dispatch(addToLogs(`Parent Transfer:\n${JSON.stringify(parentTransfer, null, 2)}`))
      if (isLinked) dispatch(addToLogs(`Parent Transfer:\n${JSON.stringify(childTransfer, null, 2)}`))
      dispatch(addToLogs(`Linked: ${isLinked.toString().toUpperCase()} - ${isLinked ? 'will also create child' : 'will not create child'}`))
      dispatch(addToLogs("Saving transfer"))
      await Promise.all([
        db.doc(dbPaths.transfers(shop, parentId)).set(addTransferSearchFields(parentTransfer)),
        ...(isLinked
          ? [db.doc(dbPaths.transfers(shop, childId)).set(addTransferSearchFields(childTransfer))]
          : []
        )
      ])
      return parentId
    } catch (error) {
      axios.post('/api/log-error', { message: `Error saving transfer: ${error.message}`, source: 'Actions' })
      console.error(error)
      dispatch(addToLogs(`Error saving transfer: ${error.message}`))
    }
  }, [addTags, axios, db, dispatch, shop, transfer, transfers])

  const saveTransferDraft = useCallback(async () => {
    savingDraftSet(true)

    saveTransfer(true)

    onCreate()
  }, [onCreate, saveTransfer])

  const createTransfer = useCallback(async () => {
    loadingSet(true)

    const id = await saveTransfer()

    dispatch(addToLogs("Creating transfer job"))
    await createJob(jobCodes.JOB_EXECUTE_TRANSFER, { id })
    dispatch(addToLogs("Transfer job created"))
    dispatch(addToLogs("Transfer create COMPLETE"))
    dispatch(addToLogs("=================================="))
    onCreate()
  }, [saveTransfer, dispatch, createJob, jobCodes.JOB_EXECUTE_TRANSFER, onCreate])

  const createButtonText = useMemo(() => {
    const isInstant = transfer.method === 'instant'
    const hasMethod = transfer.method && !isInstant
    const methodText = hasMethod && transfer.type === 'internal' && !transfer.parent
      ? `(${textsMethod[transfer.method].title})`
      : 'now'

    return (
      <ButtonTextWrap hasIcon={isInstant}>
        {isInstant && !loading &&
          <ButtonIconWrap>
            <svg fill="currentColor" x="0px" y="0px" viewBox="0 0 100 100" ><g><path d="M32.2,86.7c-0.2,0-0.4-0.1-0.6-0.2c-0.5-0.3-0.7-0.8-0.5-1.3l11.2-32.9L34,47.9c-0.3-0.2-0.5-0.5-0.6-0.8   c-0.1-0.4,0.1-0.7,0.3-1l33.8-34.7c0.4-0.4,1-0.5,1.4-0.2c0.5,0.3,0.7,0.8,0.5,1.3L58.1,45.4l8.3,4.5c0.3,0.2,0.5,0.5,0.6,0.8   c0.1,0.4-0.1,0.7-0.3,1L33,86.4C32.8,86.6,32.5,86.7,32.2,86.7z"></path></g></svg>
          </ButtonIconWrap>
        }
        {`${textsTransfer[transfer.type]?.create} ${methodText}`}
      </ButtonTextWrap>
    )
  }, [loading, transfer.method, transfer.parent, transfer.type])

  useEffect(() => {
    if (transfer.method !== defaultMethod) advancedActiveSet(true)
    // only want to run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      title="Actions"
      actions={
        showAdvanced &&
           [{
            content: advancedActive ? 'Simple' : 'Advanced',
            onAction: toggleAdvanced,
            disabled: disableAdvanced
          }]
      }
    >
      <Card.Section>
        <Stack vertical spacing="tight">
          <Collapsible
            open={advancedActive && showAdvanced && !disableAdvanced}
            transition={{ duration: '500ms', timingFunction: 'ease-in-out' }}
            expandOnPrint
          >
            <TransferMethod
              loading={loading}
            />

          </Collapsible>
          <Button
            fullWidth
            primary
            onClick={createTransfer}
            disabled={disabled || savingDraft}
            loading={loading}
          >
            {createButtonText}
          </Button>
          <FeatureToggle
            feature="draft_transfers"
            preview
            hideLock
          >
            <Button
              fullWidth
              disabled={disabled || loading}
              loading={savingDraft}
              onClick={saveTransferDraft}
            >
              <Stack wrap={false} alignment="center">
                <Stack.Item>Save draft</Stack.Item>
                {!hasFeature('draft_transfers') && <Icon source={LockMajor} />}
              </Stack>
            </Button>
          </FeatureToggle>


        </Stack>
      </Card.Section>

    </Card>


  );
}
const ButtonTextWrap = styled.div`
  position: relative;
  ${ props => props.hasIcon 
    ? `
      padding-left: 2.1rem;`
    : ''
  }
  
`
const ButtonIconWrap = styled.span`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-45%);

  svg {
    fill: currentColor;
    height: 2.2rem;
  }
`

export default withFirebase(Actions)
