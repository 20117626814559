import { useEffect } from 'react';
import useIndexedDb from "./useIndexedDb";

function useSessionHeartbeat() {
  const indexedDb = useIndexedDb()

  useEffect(() => {
    sessionStorage.setItem('sessionId', crypto.randomUUID());
  }, []);

  useEffect(() => {
    const id = sessionStorage.getItem('sessionId')

    async function updateSessionHeartbeat() {
      await indexedDb.sessions.put({ id, timestamp: Date.now() });
    }

    // Helper to clean up expired sessions
    async function cleanupExpiredSessions(expirationTime = 4000) {
      const now = Date.now();
      await indexedDb.sessions
        .where('timestamp')
        .below(now - expirationTime)
        .delete(); // Deletes expired sessions directly
    }

    // Start heartbeat immediately and set an interval
    updateSessionHeartbeat();
    const heartbeatInterval = setInterval(updateSessionHeartbeat, 3000);

    // Clean up expired sessions on component mount
    cleanupExpiredSessions();

    // Clear interval on component unmount
    return () => clearInterval(heartbeatInterval);
  }, [indexedDb]); // Dependency on db to ensure it’s accessible

}

export default useSessionHeartbeat;
