import './style.scss'

import { withFirebase } from 'providers/firebase'

import { useState, useEffect, useCallback, useMemo } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import {
  Page,
  Layout,
  Card,
  Stack,
  Banner,
  Link,
  Icon,
  Collapsible,
  TextContainer,
  Button,
} from '@shopify/polaris';

import {
  LockMajor
} from '@shopify/polaris-icons';

import { textsTransfer } from 'refs/resource-names'

import { Table, Tbody } from './components/styled-components'

import InventoryItem from './components/InventoryItem'
import Details from './components/Details'
import Locations from './components/Locations'

import { objectToArray, getQueryParam } from 'utils/helpers';
import { helpNewTicketUrl, transfersLocation } from 'refs/links';
import { StatusBadge } from 'utils/formatters';
import Skeleton from 'layout/components/Skeleton';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { dbPaths } from 'refs/db-paths';
import useJob from 'hooks/useJobs';
import InventoryFetcher from 'components/InventoryFetcher/InventoryFetcher';
import { setTransfer } from 'redux/slices/transfer';
import { DateTime } from 'luxon'
import LinkedIndicator from './components/LinkedIndicator';
import useFeatures from 'hooks/useFeatures';
import TransferTotals from './components/TransferTotals';
import TransferPrintWrapper, { callPrintTransfer } from 'components/TransferPrintWrapper/TransferPrintWrapper';
import useExportToCSV from 'hooks/useExportToCSV';



function TransfersView(props) {
  const { db, firestore, shop } = props
  const [loading, loadingSet] = useState(true)
  const [retrying, retryingSet] = useState(false)
  const [showErrors, showErrorsSet] = useState(false)
  const { id } = useParams()
  const returnTo = getQueryParam('returnTo')

  const {
    transfer,
  } = useAppSelector(s => s)

  const dispatch = useAppDispatch()

  const history = useHistory()

  const { hasFeature } = useFeatures()

  const { exportTransferToCSV } = useExportToCSV()

  const { awaitJob, jobCodes } = useJob()

  const dbRef = useMemo(() => db.doc(dbPaths.transfers(shop, id)), [db, id, shop])

  const printTransfer = useCallback(callPrintTransfer,[])

  const toggleErrors = useCallback(() => showErrorsSet((open) => !open), []);

  const retryTransfer = useCallback(async () => {
    try {
      retryingSet(true)

      await dbRef.update({
        status: 'processing',
        error: firestore.FieldValue.delete(),
        had_error: true,
      });

      await awaitJob(jobCodes.JOB_EXECUTE_TRANSFER, { id })
      retryingSet(false)
    } catch (error) {
      console.error(error);
      retryingSet(false)
    }
  }, [awaitJob, dbRef, firestore.FieldValue, id, jobCodes.JOB_EXECUTE_TRANSFER])

  const cancelTransfer = useCallback(async () => {
    loadingSet(true)
    dbRef.update({ status: 'canceled', time_canceled: new Date().toISOString() })
  }, [dbRef])

  const errors = useMemo(() => {
      try {
        return JSON.stringify(JSON.parse(transfer.errors || "{}"), undefined, 2)
      } catch (error) {
        return ''
      }
  }, [transfer.errors])


  useEffect(() => {
    if (
      transfer.status === 'processing'
      && transfer.time_execution_called
      && new Date() - new Date(transfer.time_execution_called) > 30000
      && !retrying
    ) {
      dbRef.update({ status: 'error' })
    }

  }, [dbRef, retrying, transfer.status, transfer.time_execution_called]);


  const items = useMemo(() => {
    return objectToArray(transfer.items)
  }, [transfer.items]);

  useEffect(() => {
    const listener = dbRef
      .onSnapshot(s => {
        const transfer = s.data() || {}
        dispatch(setTransfer(transfer))
        if (transfer.status === 'draft') {
          history.replace(`/transfers/${transfer.type}/${id}?returnTo=${returnTo}`)
        } else {
          loadingSet(false)
        }
      })
    return () => listener()
  }, [dbRef, dispatch, history, id, returnTo]);

  useEffect(() => {
    loadingSet(true)
  }, [id]);

  if (loading) return <Skeleton.Transfer />

  return (
    <TransferPrintWrapper>
      <Page
        title={
          <Stack alignment="center">
            <Stack.Item>{transfer.name}</Stack.Item>
            <StatusBadge status={transfer.status} />
          </Stack>
        }
        secondaryActions={[
          {content: 'Print', onAction: printTransfer},
        ]}
        actionGroups={[
          {
            title: 'Export',
            actions: [
              {
                content: 'Export to CSV (for Excel, Numbers, Sheets etc)',
                onAction: () => exportTransferToCSV(true),//hasFeature('csv') ? exportTransferToCSV : () => gotoUpgrade('csv', true),
                suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
              },
              {
                content: 'Export to CSV (plain csv)',
                onAction: () => exportTransferToCSV(false),//hasFeature('csv') ? exportTransferToCSV : () => gotoUpgrade('csv', true),
                suffix: !hasFeature('csv') && <Icon source={LockMajor} />,
              }

            ]
          }
        ]}
        subtitle={textsTransfer[transfer.type]?.title}
        breadcrumbs={[{ content: 'Transfers', url: returnTo || transfersLocation() }]}
      >

        <InventoryFetcher/>

        <Layout>
          <Layout.Section>
            <Stack vertical>
              {retrying &&
                <Banner title="Retrying..." status="info" />
              }
              {transfer.status === 'error' && !retrying &&
                <Banner
                  status="critical"
                  title="Error while processing transfer"
                  secondaryAction={{ content: "Cancel transfer", onAction: cancelTransfer }}
                  action={{ content: "Retry", onAction: retryTransfer }}
                >
                  <p>{transfer.error || 'We ran into trouble while processing your transfer.'}</p>
                  <p>Retry transfer to run again, cancel transfer, or <Link url={helpNewTicketUrl()} external>contact support for help</Link></p>
                  {errors &&
                    <>
                      <Button plain onClick={toggleErrors}>{showErrors ? 'Hide errors' : 'Show errors'}</Button>
                      <Collapsible
                        open={showErrors}
                        id="errors-collapsible"
                        transition={{duration: '500ms', timingFunction: 'ease-in-out'}}
                        expandOnPrint
                      >
                        <TextContainer>
                          <pre style={{whiteSpace: 'break-spaces'}}>
                            {errors}
                            </pre>
                        </TextContainer>
                      </Collapsible>
                    </>
                  }
                </Banner>
              }
              <Card>
                <Table>
                  <Tbody>
                    {
                      items.map(item => (
                        <InventoryItem
                          key={item.id}
                          item={item}
                          hideControls
                          thumbSize="medium"
                          showQty
                          transferType={transfer.type}
                          newContext={false}
                          canceled={transfer.status === 'canceled'}
                        />
                      ))
                    }
                  </Tbody>
                </Table>
              </Card>

            </Stack>
          </Layout.Section>

          <Layout.Section secondary>
            <Card title="Overview">
              <TransferTotals/>
              {(transfer.parent || transfer.child) &&
                <Card.Section title="Split transfer">
                  <LinkedIndicator transfer={transfer} />
                </Card.Section>
              }
              <Locations displayOnly/>

            </Card>

            <Details autoSave/>
            <Card sectioned subdued title="Information">
              <Stack spacing="extraTight">
                <Stack.Item>Created: {DateTime.fromISO(transfer.time_created).toLocaleString(DateTime.DATETIME_MED)}</Stack.Item>
                <Stack.Item>{transfer.time_executed && `Transfered: ${DateTime.fromISO(transfer.time_executed).toLocaleString(DateTime.DATETIME_MED)}`}</Stack.Item>
                <Stack.Item>{transfer.time_canceled && `Canceled: ${DateTime.fromISO(transfer.time_canceled).toLocaleString(DateTime.DATETIME_MED)}`}</Stack.Item>
              </Stack>
            </Card>

          </Layout.Section>

        </Layout>

      </Page>
    </TransferPrintWrapper>
  );
}


export default withFirebase(TransfersView)
