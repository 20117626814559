
export function objectToArray (obj, index = 'index', keyString = 'id' ) {
  if (Object.keys(obj || {}).length === 0 ) return []

  var arr = []  
  var keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    
    if (typeof obj[key] !== 'undefined') {
      const element = { ...obj[key] }
      element[keyString] = key
      arr.push(element)
    }
  }

  var compare = (a, b) => {
    if (a[index] < b[index]) return -1
    if (a[index] > b[index]) return 1
    return 0
  }
  return arr.sort(compare)
}

export function sortArray(arr, index = 'index' ) {
  if (!Array.isArray(arr)) return []
  var compare = (a, b) => {
    if (a[index] < b[index]) return -1
    if (a[index] > b[index]) return 1
    return 0
  }
  return arr.sort(compare)
}

export function arrayToObject (arr = []) {
  if (arr.length === 0 ) return {}

  var obj = {}

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i]
    const id = item.id || i
    obj[id] = { ...item, index: i }
    
  }
  return obj
}


function getFormattedDate(date, prefomattedDate = false, hideYear = false) {  
  const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  
  function pad(d) {
    return (d < 10) ? '0' + d.toString() : d.toString();
  }
  
  const day = date.getDate();
  const month = MONTH_NAMES[date.getMonth()];
  const year = date.getFullYear();
  const fullhours = date.getHours();  
  const hours = fullhours === 0 ? 12 : fullhours > 12 ? pad(fullhours - 12) : pad(fullhours)
  const ap = fullhours > 11 ? 'pm' : 'am'
  let minutes = date.getMinutes();
  
  if (minutes < 10) {
    // Adding leading zero to minutes
    minutes = `0${ minutes }`;
  }
  
  if (prefomattedDate) {
    // Today at 10:20
    // Yesterday at 10:20
    // if (prefomattedDate === 'Today') return `${ hours }:${ minutes } ${ ap }`
    return `${ prefomattedDate } at ${ hours }:${ minutes } ${ ap }`;
  }
  
  if (hideYear) {
    // 10 January at 10:20
    return `${ day } ${ month.substring(0, 3) }`// at ${ hours }:${ minutes } ${ ap }`;
  }
  // 10 January 2017 at 10:20
  return `${ day } ${ month.substring(0, 3) } ${ year }`// at ${ hours }:${ minutes } ${ ap }`;
  
}

// --- Main function
export function timeAgo(dateParam) {
  if (!dateParam) return null;
  if (typeof dateParam === 'string' && dateParam.toLowerCase() === 'never') return dateParam;

  const date = typeof dateParam === 'object' 
    ? dateParam 
    : dateParam.toString().length === 10
      ? new Date(dateParam * 1000)
      : new Date(dateParam); 
  
  const DAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
  const today = new Date();
  const yesterday = new Date(today - DAY_IN_MS);
  const seconds = Math.round((today - date) / 1000);
  const minutes = Math.round(seconds / 60);
  const dayName = DAY_NAMES[date.getDay()]
  const daysAgo = Math.round(Math.abs((today - date) / DAY_IN_MS))
  const isToday = today.toDateString() === date.toDateString();
  const isYesterday = yesterday.toDateString() === date.toDateString();
  const isThisYear = today.getFullYear() === date.getFullYear();
  
  if (seconds < 5) {
    return 'now';
  } else if (seconds < 60) {
    return `${ seconds } seconds ago`;
  } else if (seconds < 90) {
    return 'about a minute ago';
  } else if (minutes < 60) {
    return `${ minutes } minutes ago`;
  } else if (isToday) {
    return getFormattedDate(date, 'Today'); // Today at 10:20
  } else if (isYesterday) {
    return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
  } else if (daysAgo < 7) {
    return getFormattedDate(date, dayName, true); // Tuesday at 10:20
  } else if (isThisYear) {
    return getFormattedDate(date, false, true); // 10 January at 10:20
  }

  return getFormattedDate(date); // 10 January 2017. at 10:20
}


export function cleanPhone (countryCode, phone) {
  let ccLen = countryCode.length
  let pLen = phone.length
  phone = phone.substr(0 , ccLen) === countryCode ? phone.substr(ccLen, pLen - ccLen) : phone
  return phone.substr(0, 10)
} 

export function validPhoneNumber (countryCode, phone) {
  let number = Number(phone.substr(0,1)) === 0 ? phone.substr(1, phone.length - 1) : phone
  return  { phoneNumber: `${countryCode}${number}`, valid: number.length >= 9 }
}

export async function copyToClipboard (text) {

    async function fallbackCopyTextToClipboard(text) {
      var successful = false
      var textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.position= "fixed";  //avoid scrolling to bottom
      textArea.style.left = '-9999px';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
    
      try {
        document.execCommand('copy');
        successful = true
      } catch (err) {
        successful = false
      }

      document.body.removeChild(textArea);
      return successful 
    }

    if (!navigator.clipboard) return fallbackCopyTextToClipboard(text)
    
    await navigator.clipboard.writeText(text)
    return true
}


export function arrayMove(arr, oldIndex, newIndex) {
  while (oldIndex < 0) {
      oldIndex += arr.length;
  }
  while (newIndex < 0) {
      newIndex += arr.length;
  }
  if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr
};

export function getQueryParam (param) {
  let location = window.location
  const query = new URLSearchParams(location.search);
  if (typeof param === 'string') {
    return query.get(param)
  } else if (Array.isArray(param)) {
    return param.map(p => query.get(p))
  } else {
    return query.get(param)
  }
}

export function mapQuerySnapshot (querySnapshot) {
  return querySnapshot.docs.map(d => ({...d.data() || {}, id: d.id }))
}

export function paginateArray(array, size, cursor) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((cursor - 1) * size, cursor * size);
}

export const getShopDomain = () => {  
  const shop = getQueryParam('shop')
  const storedShop = sessionStorage.getItem('shop')
  if (shop) sessionStorage.setItem('shop', shop)

  return shop || storedShop
}

export const paramsToQuery = (params, appendQuery) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const currentParams = Object.fromEntries(urlSearchParams.entries());

  if (!params && !appendQuery) return '';
  if (!params && appendQuery) return window.location.search

  const stringedPairs = Object
      .entries({ ...(appendQuery ?  currentParams : {}),  ...params})
      .filter((p) => !!p && typeof p[1] !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');

  return `?${stringedPairs}`;
};

export function highlightResourceItem (id, className) {
  const highlighted = Array.from(document.getElementsByClassName(className))
  const target = document.getElementById(id)

  highlighted.forEach(el => el.classList.remove(className))

  if(target) target.classList.add(className)
}


export function focusInput (id) {
  setTimeout(() => {
    const field = document.getElementById(id)

    if (field) field.focus()
  }, 50);
}


export function generateId () {
  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  return (1000000000000000 - Number(`${Number(new Date())}${getRandomInt(10, 99)}`)).toString()
}

export function tagsToMap (arr = []) {
  if (arr.length === 0 ) return {}

  var obj = {}

  arr.forEach(t => obj[t] = true)
  
  return obj
}

export function arrayHasValues (array, values) {
  if (!Array.isArray(array) || !Array.isArray(values)) return false
  return values.every((val) => array.includes(val));
}


export function greeting () {
  var data = [
    [22, 'Working late'],
    [18, 'Good evening'],
    [12, 'Good afternoon'],
    [5,  'Good morning'],
    [0,  'Early bird']
],
hr = new Date().getHours();
for (var i = 0; i < data.length; i++) {
    if (hr >= data[i][0]) {
        return data[i][1]
    }
}
}

export async function preLoadImage (src) {
  try {
    if (src) {
      let preLoad = new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = resolve
        img.onerror = reject
        img.src = src
      })
      await preLoad
      return src
    }
    return null
  } catch (error) {
    return null
  }
}


export function addTransferSearchFields (transfer) {
  const { origin, destination } = transfer
  const tags_map = tagsToMap(transfer.tags || [])
  const locations = [
    destination,
    ...(origin ? [origin] : [])
  ]

  return {
    ...transfer,
    locations,
    tags_map
  }
}

export function toTitleCase (str) {
  return str.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
}