import './assets/App.scss'

import React from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import Layout from 'layout/Layout';
import Plans from 'views/app/plans/Plans';
import ErrorPage from 'views/app/error/ErrorPage';
import Article from 'views/app/article/Article';
import Info from 'views/app/info/Info';

import { getQueryParam, getShopDomain } from './utils/helpers'
import FirebaseContext from 'providers/firebase';
import { useAppDispatch } from 'hooks/hooks';
import { setShop } from 'redux/slices/env.ts';
import ZendeskConfigure from 'components/ZendeskConfigure/ZendeskConfigure';
import { setHost } from 'redux/slices/env';
import AppData from 'AppData';
import useSessionHeartbeat from "hooks/useSessionHeartbeat";


export default function App () {  
  const shop = getShopDomain()
  const host = getQueryParam('host') || btoa(`${shop}/admin`)

  const dispatch = useAppDispatch()
  
  dispatch(setShop(shop))
  dispatch(setHost(host))

  useSessionHeartbeat()

  return (
    <FirebaseContext.Provider value={{shop, host}}>
        <Router>
          <AppData/>
          <ZendeskConfigure/>
          <Switch>
            <Route path="/app/error" component={ErrorPage}/>
            <Route path="/app/info" component={Info}/>
            <Route path="/app/plans" component={Plans}/>
            <Route path="/app/article/:id" component={Article}/>
            <Route path="/*" component={Layout}/>
          </Switch>
        </Router>
    </FirebaseContext.Provider>
  )
}
