import { withFirebase } from 'providers/firebase';

import { useCallback, useState, useMemo, useEffect } from 'react';

import {
  ResourceList,
  ResourceItem,
  TextStyle,
  Pagination,
  Stack,
  Card,
  Icon,
  DisplayText,
} from '@shopify/polaris';

import {
  ArrowRightMinor,
} from '@shopify/polaris-icons';


import styled from 'styled-components'
import { mapQuerySnapshot } from 'utils/helpers'
import { textsTransfer } from 'refs/resource-names'
import { StatusBadge } from 'utils/formatters'
import { useAppSelector } from 'hooks/hooks';
import LinkedIndicator from 'views/transfers/components/LinkedIndicator';
import { useLocation } from 'react-router';
import FiltersControl from "./FilterControl";
import { selectFilterValues } from "redux/slices/transfers";
import { DateTime } from "luxon";

function TransfersList(props) {
  const {
    dbRef,
    showOpenTransfers,
    live,
    noLimit,
    hideControls,
    hideOnEmpty,
    noTransfersText = 'No transfers',
    hideTags = false
  } = props
  const [transfers, transfersSet] = useState([]);
  const [loading, loadingSet] = useState(true);
  const [page, pageSet] = useState(0);
  const [cursors, cursorsSet] = useState([]);
  const [hasNext, hasNextSet] = useState(false);


  const paginationLimit = 10

  const {
    suppliers,
    appData: {
      locations,
      counts: {
        transfers: transferCount = 0
      }
    },
    transfers: {
      open: openTransfers,
      filters,
      filterQuery,
      _init: transferesHasInit
    }
  } = useAppSelector(s => s)

  const filterValues = useAppSelector(selectFilterValues)

  const { pathname, search } = useLocation()

  const handlePagination = useCallback((direction) => {
    let move = 1 * (direction === 'prev' ? -1 : 1)
    pageSet(p => p + move)
  }, [])


  const items = useMemo(() => {
    return transfers
      .map(t => {
        const { id, type, origin, destination } = t
        let originName = origin
          ? type === 'internal'
            ? (locations[origin] || {}).name || ''
            : (suppliers[origin] || {}).name || ''
          : null
        let destinationName = (locations[destination] || {}).name || ''
        let url = `/transfers/${id}?returnTo=${encodeURIComponent(`${pathname}${search}`)}`
        let typeName = textsTransfer[type].name

        return { ...t, originName, destinationName, url, typeName }
      })
  }, [transfers, locations, suppliers, pathname, search]);

  const cursor = useMemo(() => cursors[page], [cursors, page])

  useEffect(() => {
    if (showOpenTransfers) transfersSet(openTransfers)
    loadingSet(!transferesHasInit)
  }, [openTransfers, showOpenTransfers, transferesHasInit]);

  useEffect(() => {
    let db = dbRef
    let listener
    
    if (!db || showOpenTransfers) return

    const setItems = (snapshot) => {
      // const cursor = snapshot.docs[snapshot.docs.length - 1];
      const cursor = snapshot.docs[paginationLimit - 1];
      const items = mapQuerySnapshot(snapshot)
      if (items.length > paginationLimit) items.pop()
      hasNextSet(snapshot.docs.length > paginationLimit)
      cursorsSet(c => ({ ...c, [page + 1]: cursor }))
      loadingSet(false)
      transfersSet(items)
    }

    loadingSet(true)

    if (filterQuery) {
      db = db.where('name', '==', filterQuery)
    } else {
      filterValues.forEach(({ field, value, operation }) => {
        if (operation === '==' && Array.isArray(value)) {
          value.forEach(t => {
            db = db.where(`${field}.${t}`, operation, true)
          })
        } else if (operation === '><') {
          const [from, to] = value
          db = db.where(field, '>=', from)
          db = db.where(field, '<=', to)
        } else {
          db = db.where(field, operation, value)
        }
      })
    }


    if (!noLimit) db = db.limit(paginationLimit  + 1)
    if (cursor) db = db.startAfter(cursor)
    if (live) {
      listener = db.onSnapshot(setItems)
    } else {
      db.get().then(setItems)
    }
    return () => {
      if (listener) listener()
    }
  }, [cursor, dbRef, filterQuery, filterValues, live, noLimit, page, showOpenTransfers]);

  useEffect(() => {
    pageSet(0)
  }, [filters]);

  if (hideOnEmpty && items.length === 0) return null


  return (
    <>
      {hideControls && items.length === 0 && !loading
        ? <div style={{ padding: '2rem 0' }}>
          <Stack vertical alignment="center" spacing="extraLoose">
            <Stack.Item>
              <Stack.Item><DisplayText size="extraSmall">{noTransfersText}</DisplayText></Stack.Item>
            </Stack.Item>
          </Stack>
        </div>
        : <ResourceList
          resourceName={{ singular: 'transfer', plural: 'transfers' }}
          items={items}
          renderItem={renderItem}
          filterControl={!hideControls && <FiltersControl />}
          loading={loading}
          totalItemsCount={transferCount}
        />
      }


      {!noLimit &&
        <BorderTop>
          <Card.Section>
            <Stack distribution="center">
              <Pagination
                hasPrevious={page > 0}
                onPrevious={() => handlePagination('prev')}
                hasNext={hasNext}
                onNext={() => handlePagination('next')}
              />
            </Stack>

          </Card.Section>
        </BorderTop>
      }
    </>

  );

  function renderItem(item) {
    const { id, url, name, origin, originName, destinationName, status, typeName, reference, tags, items = {}, time_executed } = item;
    const itemCount = Object.keys(items).length
    return (
      <ResourceItem id={id} url={url} key={id}>
        <Stack alignment="center">
          <Stack.Item fill>
            <h3>
              <TextStyle variation="strong">{name}</TextStyle> • {typeName} 
              {time_executed && <TextStyle variation="subdued"> • {DateTime.fromISO(time_executed).toLocaleString(DateTime.DATETIME_MED)}</TextStyle>} 
            </h3>

              <TextStyle variation="subdued">
                <Stack>
                {origin && <Stack.Item>{originName ? originName : <i>Deleted supplier</i>}</Stack.Item>}
                {origin && <Icon source={ArrowRightMinor} color="subdued" />}
                <Stack.Item>{destinationName}</Stack.Item>
                <Stack.Item>({itemCount})</Stack.Item>
                
              </Stack>
              </TextStyle>
              {reference && <TextStyle variation="subdued">{reference}</TextStyle>
            }

          </Stack.Item>

          {!hideTags &&
            <Stack.Item>{tags?.map(t => <Tag size="small" key={t}>{t}</Tag>)}</Stack.Item>
          }
          <Stack.Item>
            <LinkedIndicator transfer={item} size="small" mini />
          </Stack.Item>
          <StatusBadge status={status} />
        </Stack>
      </ResourceItem>
    );
  }

}

const BorderTop = styled.div`
  border-top: .1rem solid var(--p-border-subdued);
`

const Tag = styled.div`
  margin-left: 0.4rem;
  font-size: 1.2rem;
  line-height: 1.6rem;
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  min-height: 2.5rem;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 2rem;
  padding-right: .8rem;
  padding-left: .8rem;
  border-radius: var(--p-border-radius-base,3px);
  background-color: var(--p-surface-neutral,#dfe3e8);
  color: var(--p-text,#212b36);
`

export default withFirebase(TransfersList)