import React, { useCallback, useEffect, useState, useMemo } from "react";

import useIndexedDb from "hooks/useIndexedDb";
import { Banner, Layout } from "@shopify/polaris";
import { textsTransfer } from "refs/resource-names";
import { toTitleCase } from "utils/helpers";
import { useAppDispatch } from "hooks/hooks";
import { setTransfer } from "redux/slices/transfer";
import { setRecoveredState } from "redux/slices/transfer-state";
import { useHistory } from "react-router";


export default function RecoveredTransfers() {
  const [transfers, setTransfers] = useState([])
  const [sessions, setSessions] = useState([])
  const indexedDb = useIndexedDb()

  const dispatch = useAppDispatch()
  const history = useHistory()

  const handleResume = useCallback(async (id) => {
    const { value: transfer } = await indexedDb.transfers.get(id)

    dispatch(setRecoveredState(true))
    dispatch(setTransfer(transfer))
    setTimeout(() => history.replace(`/transfers/${transfer.type}/${transfer.id}`))
  }, [dispatch, history, indexedDb.transfers])

  const handleDiscard = useCallback((id) => {
    indexedDb.transfers.delete(id)
    setTransfers(transfers.filter(t => t.id !== id))
  }, [indexedDb.transfers, transfers])


  useEffect(() => {
    async function checkForUnsavedTransfers() {
      const now = Date.now();
      await indexedDb.sessions.where('timestamp').below(now - 4000).delete(); 

      const sessions = await indexedDb.sessions.toArray()
      setSessions(sessions.map(({ id }) => id))

      const transfers = await indexedDb.transfers.toArray()
      setTransfers(transfers)

    }

    checkForUnsavedTransfers()
  }, [indexedDb.sessions, indexedDb.transfers]);

  const unsavedTransfers = useMemo(() => {
    return transfers
      .filter(t => !sessions.includes(t.sessionId))
      .map(t => t.value)
  }, [sessions, transfers])

  if (sessions.length === 0) return null

  return (
    <Layout.Section>
      {unsavedTransfers.map(t => (
        <Banner
          key={t.id}
          status="warning"
          title={`Recovered transfer`}
          action={{ content: "Resume", onAction: () => handleResume(t.id) }}
          secondaryAction={{ content: "Discard", onAction: () => handleDiscard(t.id) }}
        >
          <p>We recovered an unsaved {toTitleCase(textsTransfer[t.type].title.toLowerCase())} with {Object.keys(t.items).length} item{Object.keys(t.items).length > 1 ? 's':''}. What would you like to do?</p>

        </Banner>
      ))

      }
    </Layout.Section>
  )

}