import { getRequestShop } from 'redux/slices/env';
import { useAppSelector } from './hooks';
import Dexie, { Table } from 'dexie';
import { formatShopName } from 'refs/shop';
import { useMemo } from 'react';

export interface Items {
  id?: number;
}
export interface Indexes {
  name: string;
  index: Object;
}

export interface Transfer {
  id: string;
  value: object;
  new: boolean;
  sessionId: string;
}

export interface Sessions {
  id: string;
  timestamp: number;
}

export class IndexedDb extends Dexie {
  items!: Table<Items>; 
  indexes!: Table<Indexes>; 
  transfers!: Table<Transfer>;
  sessions!: Table<Sessions>;
  
  constructor(shop: String) {
    super(`${formatShopName(shop)}-inventory`);
    this.version(2).stores({
      items: 'id',
      indexes: 'name, index',
      transfers: 'id',
      sessions: 'id, timestamp'
    });
  }
}

export default function useIndexedDb() {
  const shop = useAppSelector(getRequestShop);
  
  const indexedDb = useMemo(() => new IndexedDb(shop), [shop]);
  
  return indexedDb;
}
